export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyB8i88BBHkTpYtznNUyTDnvtvhibgA1tx4",
    authDomain: "xplainer-75cd2.firebaseapp.com",
    projectId: "xplainer-75cd2",
    storageBucket: "xplainer-75cd2.appspot.com",
    messagingSenderId: "296049862450",
    appId: "1:296049862450:web:3b4389629072c20bfdd92b",
    measurementId: "G-WEY2MP10PP"
  },
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  stripe: {
    sk: ''
  },
  paypal: {
    sandbox: '',
    production: 'YOUR_PRODUCTION_CLIENT_ID'
  },
  general: {
    symbol: '$',
    code: 'MXN'
  },
  // idRestaurante : "cFdHiCUZ4aadrnjBU2vqof5Oay82"
     idRestaurante : "yJuOikhujLNiQ2nWl5TUeUj40OI3"
};
