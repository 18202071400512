<ion-app>
    <!--   Menu principal -->
    <ion-menu contentId="main-content">
      <ion-header>
        <div class="header">
          <img src="../assets/imgs/LOGOTIPO LOS GUEROS.png" alt="" style="width: 40px" />
          <svg (click)="closeMenu()" data-testid="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="sc-jjj11z-1 jLNweb">
            <path fill="#6A696E" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3a.996.996 0 01-1.41 0L12 13.41 9.11 16.3a.996.996 0 11-1.41-1.41L10.59 12 7.7 9.11A.996.996 0 119.11 7.7L12 10.59l2.89-2.89a.996.996 0 111.41 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"></path>
          </svg>
        </div>
      </ion-header>
      <ion-content>
        <div class="line__divider"></div>
        <div class="user" *ngIf="loggedIn">
          <div class="user__info">
            <img *ngIf="photo != '' " [src]="photo" alt="" srcset="">
            <span *ngIf="photo == '' ">{{ name? name[0] : "U" }}</span>
          </div>
          <div class="user__data">
            <div class="">
              <span class="user__data__Hi">Hola, <span class="user__data__name">{{ name }}</span>
              </span>
            </div>
          </div>
        </div>
        <!-- Menus-->
        <ion-list lines="none">
          <ion-list-header> Taqueria los Gueros </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/tabs/home" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label> Inicio </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <!-- corregir -->
            <ion-item (click)="Taquiza()">
              <ion-icon slot="start" name="restaurant"></ion-icon>
              <ion-label> Whatsapp </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!--
                  <ion-menu-toggle autoHide="false"><ion-item routerLink="/inbox" routerLinkActive="active" routerDirection="root" detail="false"><ion-icon slot="start" name="help"></ion-icon><ion-label> Soporte </ion-label></ion-item></ion-menu-toggle>
                  -->
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/main/AvisoPrivacidad" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="information-circle"></ion-icon>
              <ion-label> Aviso de Privacidad </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <!-- Perfil-->
        <ion-list *ngIf="loggedIn" lines="none">
          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label> Cerrar Sesión </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-list-header> Cuenta </ion-list-header>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label> Login </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label> Soporte </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/signup" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="person-add"></ion-icon>
              <ion-label> Registro </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!--
                  <ion-item><ion-icon slot="start" name="moon-outline"></ion-icon><ion-label> Dark Mode </ion-label><ion-toggle></ion-toggle></ion-item>
   -->
        </ion-list>

        <!-- compartir -->
        <ion-list>
          <ion-list-header>Comparte nuestra app</ion-list-header>
          <ion-item>
            <ion-label>
              <ion-button (click)="copyToClipboard()" expand="block">Copiar dirección</ion-button>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-button [href]="facebookShareUrl" target="_blank" expand="block">Compartir en Facebook</ion-button>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <ion-button [href]="mailtoLink" expand="block">Compartir por Correo</ion-button>
            </ion-label>
          </ion-item>

          <!-- redes sociales -->
          <div class="social__icons">
            <a href="https://api.whatsapp.com/send?phone=+525560712115&text=Quiero%20más%20información%20" target="_blank">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </a>
            <a href="https://www.facebook.com/TaqueriaLosGueros1" target="_blank">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
            <a href="https://instagram.com/losgueros_1979" target="_blank">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </div>
        </ion-list>
      </ion-content>

    </ion-menu>
    
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
  <splash-screen></splash-screen>