import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import * as firebase from 'firebase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PushNotificationService {

  // messagingFirebase : firebase.messaging.Messaging;
  // db = firebase.firestore();

  constructor(    private adb: AngularFirestore,private messagingFirebase : AngularFireMessaging
    ) { 

      // this.messagingFirebase = firebase.messaging();


    }

  requestPermission = () => {
    return new Promise(async (resolve, reject) => {
      const permsis = await Notification.requestPermission();
      if (permsis === "granted") {
        const tokenFirebase = await this.messagingFirebase.getToken.subscribe(c=>{
          resolve(c);
        });
      } else {
        reject(new Error("No se otorgaron los permisos"))
      }
    })
  }

  private messaginObservable = new Observable<any>(observe => {
    this.messagingFirebase.onMessage(payload => {
      observe.next(payload)
    })
    
  })

  receiveMessage() {
    return this.messaginObservable;
  }

  updatefcmToken(token) {
    let uid = localStorage.getItem("uid") || null;
    if (uid) {
      this.adb.collection("users").doc(uid).update({ fcm_token: token }).then(c=> {
        //console.log(Update FCM Token')
      });
    }
  }

}
