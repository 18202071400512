import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { ApisService } from "src/app/services/apis.service";
import { UtilService } from "src/app/services/util.service";
import { NavController, PopoverController } from "@ionic/angular";
import { PopoverComponent } from "src/app/components/popover/popover.component";
import Swal from "sweetalert2";
import * as moment from "moment";
import { CartService } from "src/app/services/cart.service";
@Component({
  selector: "app-choose-address",
  templateUrl: "./choose-address.page.html",
  styleUrls: ["./choose-address.page.scss"],
})
export class ChooseAddressPage implements OnInit {
  id: any;
  myaddress: any[] = [];
  from: any;
  selectedAddress: any;
  dummy = Array(10);
  totalPrice: number;
  totalItem: number;
  cart: any[];
  coupon: any;
  dicount: any;
  grandTotal: any;
  deliveryCharge: number;
  constructor(
    private router: Router,
    private api: ApisService,
    private util: UtilService,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private popoverController: PopoverController,
    private cartServ: CartService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((data) => {
      console.log(data);
      if (data && data.from) {
        this.from = data.from;
      }
    });
  }

  getAddress() {
    this.api
      .getMyAddress(this.id)
      .then(
        (data) => {
          console.log("my address", data);
          this.dummy = [];
          if (data && data.length) {
            this.myaddress = data;
          }
        },
        (error) => {
          console.log(error);
          this.dummy = [];
        }
      )
      .catch((error) => {
        console.log(error);
        this.dummy = [];
      });
  }

  /**
   * Esperamos el metodo cuando regrese el usuario
   */
  ionViewWillEnter() {
    this.api.checkAuth().then((data: any) => {
      console.log(data);
      if (data) {
        this.id = data.uid;
        this.getAddress();
      }
    });
  }
  /**
   * Agregamos nueva direccion
   */
  addNew() {
    this.router.navigate(["add-new-address"]);
  }

  /**
   * Seleccionamos la direccion y continuamos
   */
  selectAddress() {
    if (this.from === "cart") {
      // if(this.selectedAddress == "branch"){
      //   const address = {
      //     address: "branch",
      //     lat: 0,
      //     lng: 0,
      //     id: 0
      //   };
      //   localStorage.setItem('deliveryAddress', JSON.stringify(address));

      // }else{
      const selecte = this.myaddress.filter(
        (x) => x.id === this.selectedAddress
      );
      const item = selecte[0];
      console.log("item", item);
      const address = {
        address: item.house + " " + item.landmark + " " + item.address,
        lat: item.lat,
        lng: item.lng,
        id: item.id,
      };
      localStorage.setItem("deliveryAddress", JSON.stringify(address));
      // }
      let data = {
        deliveryAddress: JSON.stringify(address),
      };
      this.cartServ.checkout(data, this.id);
      this.router.navigate(["payments"]);
    }
  }

  /**
   * Metodo para editar la direccion
   * @param item Editamos la direccion
   */
  edit(item) {
    const navData: NavigationExtras = {
      queryParams: {
        from: "edit",
        data: JSON.stringify(item),
      },
    };
    this.router.navigate(["add-new-address"], navData);
  }

  /**
   * Metodo para eliminar la direccion
   * @param item
   */
  remove(item) {
    Swal.fire({
      title: "Eliminar esta dirección",
      text: "¿Estás seguro de que deseas eliminar esta dirección?",
      icon: "question",
      confirmButtonText: "Si, seguro",
      customClass: {
        popup: "popup-class",
        header: "header-class",
        title: "title-class",
        closeButton: "close-button-class",
        icon: "icon-class",
        image: "image-class",
        content: "content-class",
        input: "input-class",
        actions: "actions-class",
        confirmButton: "confirm-button-class",
        cancelButton: "cancel-button-class",
        footer: "footer-class",
      },
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
    }).then((data) => {
      console.log(data);
      if (data && data.value) {
        this.util.show();
        this.api
          .deleteAddress(localStorage.getItem("uid"), item.id)
          .then((data) => {
            console.log(data);
            this.util.hide();
            this.getAddress();
          })
          .catch((error) => {
            console.log(error);
            this.util.hide();
          });
      }
    });
  }
}
