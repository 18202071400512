import { UtilService } from "src/app/services/util.service";
import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { CartService } from "src/app/services/cart.service";
import { fromEvent, Subscription } from "rxjs";

@Component({
  selector: "app-variations",
  templateUrl: "./variations.page.html",
  styleUrls: ["./variations.page.scss"],
})
export class VariationsPage implements OnInit {
  productName: any = "";
  desc: any = "";
  total: any = 0;
  lists: any[] = [];
  cart: any[] = [];
  userCart: any[] = [];

  sameProduct: boolean = false;
  removeProduct: boolean = false;

  radioSelected: any;
  haveSize: boolean;

  newItem: boolean = false;

  sameCart: any[] = [];
  image_url: any = "";

  message: string = "";

  quantiyOrden: number = 1;
  price: any = 0;
  productID: string;

  private backbuttonSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private navParma: NavParams,
    private util: UtilService,
    private cartServ : CartService
  ) {
    // debugger
    //Obtenemos el producto
    const info = this.navParma.get("food");
    //obtenemos el carrito
    // const userCart = cartServ.getCart().then(c=> c).catch(err=> err);

    // this.userCart = userCart.filter((c) => c.quantiy > 0);

    //cargamos la informacion
    this.productName = info.name;
    this.desc = info.desc;
    this.lists = info.variations;
    this.haveSize = info.size;
    this.image_url = info.cover;
    this.price = info.price;
    this.productID = info.id;
    
    if (info.selectedItem.length > 0) {
      this.sameProduct = true;
      this.sameCart = info.selectedItem;
    }
    
    // console.error("usercart---->", userCart);
    // if (userCart.length > 0) {
      
    // } else {
    //   this.userCart = [];
    // }
  }

  changeQuantiy(number) {
    this.quantiyOrden = this.quantiyOrden + number;
    if (this.quantiyOrden == 0) {
      this.quantiyOrden = 1;
    }
  }

  ngOnInit() {
    const event = fromEvent(document, 'backbutton');
    this.backbuttonSubscription = event.subscribe(async () => {
      console.log("Aqui debe de tener ");
      
        const modal = await this.modalController.getTop();
        if (modal) {
            modal.dismiss();
        }
    });
}
ngOnDestroy() {
  this.backbuttonSubscription.unsubscribe();
}

 

  
  closeIt() {
    this.modalController.dismiss();
  }

  radioGroupChange(event, title) {
    //debugger;
    const radioList = this.lists.filter((x) => x.title === title);

    const selectedItems = radioList[0].items.filter(
      (x) => x.title === event.detail.value
    );

    const price = parseFloat(selectedItems[0].price) || 0;
    const param = {
      type: title,
      value: price ,
      name: selectedItems[0].title,
    };

    const index = this.cart.findIndex((x) => x.type === title);

    if (index >= 0) {
      this.cart[index].value = price;
    } else {
      this.cart.push(param);
    }

    // console.log(event, title);

    // console.log(this.lists);
    // const radioList = this.lists.filter((x) => x.title === title);
    // console.log(radioList);
    // const selectedItems = radioList[0].items.filter(
    //   (x) => x.title === event.detail.value
    // );
    // console.log("selected item", selectedItems);
    // const price = parseFloat(selectedItems[0].price);
    // const param = {
    //   type: title,
    //   value: price,
    //   name: selectedItems[0].title,
    // };
    // const item = this.cart.filter((x) => x.type === title);
    // // console.log(item);

    // if (item && item.length) {
    //   const index = this.cart.findIndex((x) => x.type === title);
    //   this.cart[index].value = price;
    // } else {
    //   this.cart.push(param);
    // }
    // console.log("cart", this.cart);
    // console.log("ng model", this.radioSelected);
  }

  checkedEvent(event, title, parent) {
    const price = parseFloat(event.detail.value) || 0;
    const param = {
      type: parent.title,
      value: price || 0,
      name: title,
    };

    if (!event.detail.checked) {
      let index = this.cart.findIndex(c => c.type ==  parent.title && c.name == title);
      if (index >= 0) {
        this.cart.splice(index,1)
      }
      // return false
    } else {
      var numberSelectedType = this.cart.filter((c) => c.type == parent.title);

      if (parent.maxitems == numberSelectedType.length) {
        event.target.checked = false;
        this.util.errorToast(
          "No es posible seleccionar mas de " + parent.maxitems
        );
        return false;
      }

      console.log(event, title);

      this.cart.push(param);
    }
  }

  sameChoise() {
    this.modalController.dismiss(this.sameCart, "sameChoice");
  }

  addToCart() {
    var continua = "";

    this.lists.map((c) => {
      //debugger;
      if (c.required == true) {
        const addedSize = this.cart.filter((x) => x.type === c.title);
        if (addedSize.length == 0) {
          continua = c.title;
        }
      }
    });

    if (continua != "") {
      this.util.errorToast(
        "Por favor la sección " + continua + " es requerida"
      );
      return false;
    }

    /*
      new
      sameChoice
      newCustom
      remove
    */
    const addedSize = this.cart.filter((x) => x.type === "size");
    console.log(addedSize);
    let role;
    if (this.haveSize && !addedSize.length) {
      console.log("no size added");
      this.util.errorToast("Por favor seleccione un tamaño");
      return false;
    }
    if (this.cart.length && !this.userCart.length) {
      role = "new";
    }
    if (this.cart.length && this.userCart.length) {
      role = "new";
    }
    if (!this.cart.length) {
      role = "dismissed";
    }
    if (this.newItem) {
      role = "newCustom";
    }

    if (this.message) {
      const paramMessage = {
        type: "Comentarios",
        value: 0,
        name: this.message,
      };

      this.cart.push(paramMessage);
    }

    let extraData = {
      items: this.cart,
      quantiy: this.quantiyOrden,
    };

    this.modalController.dismiss(extraData, role);
  }

  addQ(index) {
    this.sameCart[index].total = this.sameCart[index].total + 1;
  }

  removeQ(index) {
    this.sameCart[index].total = this.sameCart[index].total - 1;
    if (this.sameCart[index].total === 0) {
      this.sameCart = this.sameCart.filter((x) => x.total !== 0);
    }

    if (this.sameCart.length < 0) {
      this.modalController.dismiss(this.cart, "remove");
    }
  }
}
