<ion-header *ngIf="sameProduct">
  <div class="header">
    <div class="bts">
      <div style="display: flex">
        <div class="image-product" style="width: 100px !important; height: 100px;">
          <img [src]="image_url" [alt]="productName" />
        </div>
        <div class="desc">
          <p class="title">{{productName}}</p>
          <p class="details">
            {{ (desc.length>40)? (desc | slice:0:40)+'..':(desc) }}
          </p>
        </div>
      </div>
      <ion-buttons slot="end">
        <ion-button (click)="closeIt()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
  </div>
</ion-header>

<ion-content>
  <!-- Same Product -->
  <div *ngIf="sameProduct" style="padding: 5px;">

    <h3>Listado de ordenes</h3>

    <div *ngFor="let item of sameCart;let i = index" class="cartItems">
      <div>
        <p class="name">{{productName}}</p>
        <div class="names">
          <p *ngFor="let sub of item.item" class="subDetails">{{sub.name}}</p>
        </div>
      </div>
      <div class="cartBtn2">
        <ion-button (click)="removeQ(i)" fill="clear" size="small">
          <ion-icon name="remove"></ion-icon>
        </ion-button>
        <p class="qunitity">{{item.total}}</p>
        <ion-button (click)="addQ(i)" fill="clear" size="small">
          <ion-icon name="add"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <!-- New Or diff variations -->
  <div *ngIf="!sameProduct" class="padder">
    
    <ion-header>
       <ion-toolbar>
         <ion-title>{{productName}}</ion-title>
         
         <ion-buttons slot="end">
           <ion-button (click)="closeIt()">
             <ion-icon name="close"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        </ion-header>
    <div class="content">
      <div style="padding: 10px">
        <div class="image-product">
          <img [src]="image_url" [alt]="productName" />
        </div>
        <p class="details">
          {{ (desc.length>40)? (desc | slice:0:40)+'..':(desc) }}
        </p>
        <p style="font-size: 16px; font-weight: 600">${{price}}</p>
      </div>
      <div>
        <!-- cantidad  -->
        <ion-list>
          <ion-list-header>
            <ion-label>Cantidad</ion-label>
          </ion-list-header>
          <ion-item>
            <div class="quantiy">
              <div class="quantiy__container">
                <div
                  class="quantiy__container__add"
                  (click)="changeQuantiy(-1)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="remove"
                  >
                    <path
                      fill="#6A696E"
                      d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
                <div class="quantiy__container__number">
                  <span
                    color="graya100"
                    data-testid="typography"
                    class="sc-bxivhb eeSaHG"
                    >{{quantiyOrden}}</span
                  >
                </div>
                <div
                  class="quantiy__container__remove"
                  (click)="changeQuantiy(+1)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="add"
                  >
                    <path
                      fill="#6a696e"
                      d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"
                    ></path>
                  </svg>
                </div>
              </div></div
          ></ion-item>
        </ion-list>

        <!-- Variaciones  -->
        <div *ngFor="let item of lists">
          <ion-list *ngIf="item.type == 'radio'">
            <ion-radio-group (ionChange)="radioGroupChange($event,item.title)">
              <ion-list-header>
                <ion-label>{{item.title}}</ion-label>
              </ion-list-header>
              <ion-item *ngFor="let sub of item.items;let i = index;">
                <ion-label style="text-align: left">{{sub.title}}</ion-label>
                <ion-label style="text-align: right">
                  {{ sub.price == 0 ? '' : '$'+sub.price}}</ion-label
                >
                <ion-radio
                  slot="start"
                  [value]="sub.title"
                  [name]="sub.title"
                  mode="md"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
          <ion-list *ngIf="item.type =='check'">
            <ion-list-header>
              <ion-label>{{item.title}}</ion-label>
            </ion-list-header>
            <ion-item *ngFor="let sub of item.items;let j = index;">
              <ion-label style="text-align: left">{{sub.title}}</ion-label>
              <ion-label style="text-align: right"
                >{{ sub.price == 0 ? '' : '$'+sub.price}}</ion-label
              >
              <ion-checkbox
                slot="start"
                [value]="sub.price"
                (ionChange)="checkedEvent($event,sub.title,item)"
                mode="md"
              >
              </ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
        <!-- Comentarios  -->
        <ion-list>
          <ion-list-header>
            <ion-label>Comentarios</ion-label>
          </ion-list-header>

          <ion-item>
            <textarea
              class="comments"
              placeholder="Añade instrucciones o comentarios..."
              [(ngModel)]="message"
            ></textarea>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>

  <div class="footers">
    <ion-button (click)="addToCart()" expand="block" *ngIf="!sameProduct">
      Agregar Orden {{ total ? '$'+total : '' }}
    </ion-button>
    <ion-row *ngIf="sameProduct">
      <ion-col size="6">
        <ion-button (click)="sameChoise()" expand="block">
          Continuar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          (click)="sameProduct = !sameProduct;newItem = true"
          expand="block"
        >
          Agregar Mas
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
