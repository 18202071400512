<div class="inner-content">
  <div class="div_content">
    <p class="title">Select Drivers</p>
  </div>
  <div class="content">
    <ion-list>
      <ion-radio-group [(ngModel)]="selectedDriver">
        <ion-list-header>
          <ion-label>Drivers</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let item of drivers" lines="none">
          <ion-avatar slot="start">
            <img [src]="item.coverImage" />
          </ion-avatar>
          <ion-label>{{item.fullname}}</ion-label>
          <ion-radio [value]="item.id"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </div>
  <div class="div_btn">
    <ion-row>
      <ion-col size="6" class="cancel">
        <ion-button (click)="select()" color="primary" expand="full">
          Select
        </ion-button>
      </ion-col>
      <ion-col size="6" class="cancel">
        <ion-button (click)="close()" color="light" expand="full">
          Cancle
        </ion-button>
      </ion-col>
    </ion-row>


  </div>
</div>