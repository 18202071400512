import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { FoodModel } from "../interfaces/product.model";

@Injectable({
  providedIn: "root",
})
export class CartService {
  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore
  ) {}

  /**
   * Comment goes here
   */
  async Add(data: FoodModel) {
    let userID = (await this.fireAuth.currentUser).uid;

    return await this.adb
      .collection("carts")
      .doc(userID)
      .collection("products")
      .doc(data.id)
      .set(data)
      .then((c) => true)
      .catch((err) => err);
  }

  async remove(data: FoodModel) {
    let userID = (await this.fireAuth.currentUser).uid;
    return await this.adb
      .collection("carts")
      .doc(userID)
      .collection("products")
      .doc(data.id)
      .delete()
      .then((c) => true)
      .catch((err) => err);
  }

  async getCart(user_ID = ""): Promise<any[]> {
 
    if (user_ID != "") {
      return await this.adb
        .collection("carts")
        .doc(user_ID)
        .collection("products")
        .get()
        .toPromise()
        .then((c) => c.docs.map((c) => c.data()))
        .catch((err) => err);
    }
 
  }

  async getProduct(id: string): Promise<FoodModel> {
    let userID = (await this.fireAuth.currentUser).uid;
    
    return await this.adb
      .collection("carts")
      .doc(userID)
      .collection("products")
      .doc(id)
      .get()
      .toPromise()
      .then((c) => c.data())
      .catch((err) => err);
  }

  async empytyCart() {
    let userID = (await this.fireAuth.currentUser).uid;
    // let res =   await this.adb.collection('carts').doc(userID).delete().then(c=> true).catch(err=> false);

  return  await this.adb
      .collection("carts")
      .doc(userID)
      .collection("products")
      .get()
      .toPromise()
      .then((c) => {
        c.docs.map((d) => {
          d.ref.delete();
        });
        return true;
      });
    ;
  }

  observerCart(userID: string) {
    return this.adb
      .collection("carts")
      .doc(userID)
      .collection("products")
      .snapshotChanges();
  }

  async checkout(data: {}, userID: string) {
    return this.adb
      .collection("carts")
      .doc(userID)
      .set(data, { merge: true })
      .then((c) => true)
      .catch((err) => false);
  }

  async getInfoCart(userID: string): Promise<any> {
    return this.adb
      .collection("carts")
      .doc(userID)
      .get()
      .toPromise()
      .then((c) => c.data())
      .catch((err) => false);
  }
}
