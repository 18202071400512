import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CitiesModel } from "../interfaces/cities.model";

export class AuthInfo {
  constructor(public $uid: string) {}

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: "root",
})
export class ApisService {
  static UNKNOWN_USER = new AuthInfo(null);

  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(
    ApisService.UNKNOWN_USER
  );

  changeCart$ = new Subject<any>();

  orderCreated$ = new Subject<boolean>();

  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private http: HttpClient
  ) {}

  public checkAuth() {
    return new Promise((resolve, reject) => {
      this.fireAuth.onAuthStateChanged((user) => {
        console.log(user);
        if (user) {
          localStorage.setItem("uid", user.uid);
          resolve(user);
        } else {
          this.logout();
          const lng = localStorage.getItem("language");
          const selectedCity = localStorage.getItem("selectedCity");
          // localStorage.clear();
          localStorage.setItem("language", lng);
          localStorage.setItem("selectedCity", selectedCity);
          resolve(false);
        }
      });
    });
  }

  public login(email: string, password: string) {
    return this.fireAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => res.user)
      .catch((err) => null);
  }

  public register(
    email: string,
    password: string,
    fullname: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          if (res.user) {
            this.adb
              .collection("users")
              .doc(res.user.uid)
              .set({
                uid: res.user.uid,
                email: email,
                fullname: fullname,
                type: "user",
                status: "active",
                fcm_token: localStorage.getItem("fcm")
                  ? localStorage.getItem("fcm")
                  : "",
              });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }

          res.user.updateProfile({
            displayName: fullname,
          });
        })
        .catch((err) => {
          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  public resetPassword(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .sendPasswordResetEmail(email)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(`reset failed ${err}`);
        });
    });
  }

  public logout(): Promise<void> {
    this.authInfo$.next(ApisService.UNKNOWN_USER);
    // this.adb.collection('users').doc(localStorage.getItem('uid')).update({ "fcm_token": firebase.firestore.FieldValue.delete() })
    return this.fireAuth.signOut();
  }

  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .get()
        .subscribe(
          (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  public getMyProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (users: any) => {
            resolve(users.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueUser(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .get()
        .subscribe(
          (venue: any) => {
            resolve(venue.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getVenueCategories(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("categories", (ref) => ref.where("uid", "==", id))
        .get()
        .subscribe(
          (venue) => {
            var data = venue.docs.map((element) => {
              var item: any = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getFoods(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("foods")
        .doc(uid)
        .collection("all")
        .get()
        .toPromise()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.cid.get().then(function (doc) {
                item.cid = doc.data();
                item.cid.id = doc.id;
              });
              item.id = doc.id;
              return item;
            });
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getMessages(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("messages")
        .doc(id)
        .collection("chats")
        .get()
        .subscribe(
          (messages: any) => {
            console.log(messages);
            let data = messages.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOffers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("offers")
        .get()
        .subscribe(
          (venue: any) => {
            // resolve(venue.data());
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOffer(code: string): Promise<any[]> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("offers", (ref) => ref.where("code", "==", code))
        .get()
        .subscribe(
          (venue: any) => {
            // resolve(venue.data());
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  //#region User Address
  public addNewAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .set(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .update(param)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteAddress(uid, id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .doc(id)
        .delete()
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getMyAddress(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("address")
        .doc(uid)
        .collection("all")
        .get()
        .toPromise()
        .then(
          (data) => {
            var users = data.docs.map((doc) => {
              var item = doc.data();
              item.id = doc.id;
              return item;
            });
            resolve(users);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  ////#endregion

  public createOrder(id, param): Promise<any> {
   // debugger
    param.vid = this.adb.collection("venue").doc(param.vid).ref;
    param.uid = this.adb.collection("users").doc(param.uid).ref;
    // param.dId = this.adb.collection('users').doc(param.dId);
  return    this.adb
        .collection("orders")
        .doc(id)
        .set({...param})
        .then(
         c=> true
        )
        .catch((err) => err);
  }

  public checkOrder(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .get()
        .toPromise()
        .then(
          (data) => {
            resolve(true);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  // sendNotification(msg, title, id) {
  //   const body = {
  //     app_id: environment.onesignal.appId,
  //     include_player_ids: [id],
  //     headings: { en: title },
  //     contents: { en: msg },
  //     data: { task: msg }
  //   };
  //   const header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', 'application/json')
  //       .set('Authorization', `Basic ${environment.onesignal.restKey}`)
  //   };
  //   return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
  // }

  async sendNotification(msg, title, uid) {
    const body = {
      title: title,
      description: msg,
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/los-gueros-43c99.appspot.com/o/FCMImages%2FFTAQUERIA-768x512.jpg?alt=media&token=9ea4cab3-13e4-468b-b62c-5298c5b9d510",
      userId: uid,
      url: "google.com",
      role: "",
    };

    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Authorization", `taquerialosgueros.com`),
    };

    let res = await this.http
      .post(
        "https://us-central1-los-gueros-43c99.cloudfunctions.net/app/push-notification",
        body,
        header
      )
      .toPromise()
      .then((c: any) => c.sending)
      .catch((c) => false);

    return true;
  }

  // sendNotificationV2(data) {
  //   debugger;
  //   return new Promise<any>((resolve, reject) => {
  //     this.adb
  //       .collection("notifications")
  //       .add(data)
  //       .then(
  //         (res) => {
  //           resolve(res);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  public getMyOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("orders", (ref) => ref.where("userId", "==", id))
        .get()
        .subscribe(
          async (venue) => {
            let data = venue.docs.map((element) => {
              let item: any = element.data();
              item.vid.get().then(function (doc) {
                item.vid = doc.data();
                item.vid.id = doc.id;
              });
              item.id = element.id;
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .get()
        .subscribe(
          async (order: any) => {
            let data = await order.data();
            await data.vid.get().then(function (doc) {
              data.vid = doc.data();
              data.vid.id = doc.id;
            });
            if (data && data.dId) {
              await data.dId.get().then(function (doc) {
                data.dId = doc.id;
                data.dId = doc.data();
              });
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getDriverInfo(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("users")
        .doc(id)
        .snapshotChanges()
        .subscribe(
          (data) => {
            console.log(data);
            resolve(data.payload.data());
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public updateOrderStatus(id, value): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection("orders")
        .doc(id)
        .update({ status: value })
        .then(async (order: any) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public addReview(param): Promise<any> {
    param.vid = this.adb.collection("venue").doc(param.vid);
    param.uid = this.adb.collection("users").doc(param.uid);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("reviews")
        .doc(Math.random().toString())
        .set(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public addDriverReview(param): Promise<any> {
    param.uid = this.adb.collection("users").doc(param.uid);
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("driverreviews")
        .doc(Math.random().toString())
        .set(param)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("venue")
        .doc(informations.uid)
        .update(informations)
        .then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateProfile(uid, param): Promise<any> {
    //debugger;
    const user = this.fireAuth.currentUser.then((user) => {
      if (user) {
        console.log(user);

        let res = user
          .updateProfile({
            displayName: param.fullname,
            photoURL: param.cover,
          })
          .then((c) => true)
          .catch((err) => err);
        console.log(res);
      }
    });

    

    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("users")
        .doc(uid)
        .update(Object.assign(param, {}))
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  public updateProfileChat(uid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(uid).update(param).then((data) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      })
    });
  }

  public getMyReviews(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("reviews", (ref) => ref.where("id", "==", id))
        .get()
        .subscribe(
          async (review) => {
            let data = review.docs.map((element) => {
              let item: any = element.data();
              item.id = element.id;
              if (item && item.vid) {
                item.vid.get().then(function (doc) {
                  item.vid = doc.data();
                });
              }
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public getBanners(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection("banners")
        .get()
        .subscribe(
          (venue: any) => {
            // resolve(venue.data());
            let data = venue.docs.map((element) => {
              let item = element.data();
              item.id = element.id;
           //   console.log("banners: " + item.id)
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  httpPost(url, body) {
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("Authorization", `Bearer ${environment.stripe.sk}`),
    };
    const order = this.JSON_to_URLEncoded(body);
    console.log(order);
    return this.http.post(url, order, header);
  }

  httpGet(url) {
    const header = {
      headers: new HttpHeaders()
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("Authorization", `Bearer ${environment.stripe.sk}`),
    };

    return this.http.get(url, header);
  }

  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element == "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }
}
