<div
  class="app-splash-screen"
  [ngStyle]="{
    left: windowWidth,
    opacity: opacityChange,
    transition: splashTransition
  }"

  *ngIf="showSplash"
>

<!--div class="splash" style="background-image: url(/assets/imgs/splashscreen.jpg);height: 100vh;width: 100vw;background-position: top;background-size: cover;">
  <div class="app-loader"></div> 

</ -->

<div class="splash"><div class="app-loader"></div> </div>


  <!-- <div class="app-splash-inner">


    <img src="/assets/imgs/splashscreen.png" alt="" style="position: absolute;
    max-width: 100%;
    top: 0px;
    height: 100vh;
    width: 100vw;
    object-fit: cover;">
    <div class="app-logo"></div>
    <div class="app-label">Taqueria los Gueros </div>
    <div class="app-loader"></div> 
  </div> -->
</div>