import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SkeletonLoadingComponent } from "./skeleton-loading/skeleton-loading.component";
import { HidenavModule } from "../directives/hidenav.module";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from "@ionic/angular";
import { CardProductComponent } from "./card-product/card-product.component";
import { ViewProductComponent } from "./view-product/view-product.component";
import { SearchProductComponent } from "./search-product/search-product.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ProcessComponent } from "./process/process.component";
import { SplashScreenComponent } from "./splash-screen/splash-screen.component";
import { OfertaComponent } from './oferta/oferta.component';

@NgModule({
    declarations: [
        SkeletonLoadingComponent,
        CardProductComponent,
        ViewProductComponent,
        SearchProductComponent,
        ProcessComponent,
        SplashScreenComponent,
        OfertaComponent
    ],
    imports: [HidenavModule, CommonModule, IonicModule],
    exports: [
        HidenavModule,
        TranslateModule,
        SkeletonLoadingComponent,
        CardProductComponent,
        ViewProductComponent,
        SearchProductComponent,
        ReactiveFormsModule,
        SplashScreenComponent,
        ProcessComponent
    ]
})
export class SharedModule {}
